export const About = [
  {
    title: "Our Story",
    path: "/about#story",
  },
  {
    title: "Our Mission",
    path: "/about#mission",
  },
  {
    title: "Our Culture",
    path: "/about#culture",
  },
  // {
  //   title: "Value",
  //   path: "/values",
  // },
  {
    title: "Executive Team",
    path: "/about#team",
  },
  {
    title: "Charity",
    path: "/charity",
  },
  // {
  //   title: "Certifications",
  //   path: "/certifications",
  // },
]

export const More = [
  {
    title: "Blog",
    path: "/blog",
  },
  {
    title: "Use Cases",
    path: "/use-cases",
  },
]
