import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
// import { Services } from "../../data/SubMenuData"
import "../../styles/Submenu.scss"

const Services = [
  // {
  //   title: "Zero Trust Security",
  //   path: "security-services",
  // },
  {
    title: "Application Management",
    path: "application-management",
  },
  {
    title: "Service Orchestration",
    path: "service-orchestration",
  },
  {
    title: "Full Stack Engineering",
    path: "full-stack-engineering",
  },
  {
    title: "Services",
    path: "services",
  },
]

const ServicesDropdown = () => {
  const [click, setClick] = useState(false)
  const handleClick = () => setClick(!click)
  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? "alt-dropdown-menu clicked" : "alt-dropdown-menu"}
      >
        {/*Column1*/}
        <ul className="column-3">
          <li>
            <Link
              to="/application-management"
              className="alt-dropdown-heading"
              onClick={() => setClick(false)}
            >
              Application Management
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/application-management#observability"
              onClick={() => setClick(false)}
            >
              Observability
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/application-management#application-modernisation"
              onClick={() => setClick(false)}
            >
              Application Modernisation
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/application-management#dev-ops"
              onClick={() => setClick(false)}
            >
              DevOps
            </Link>
          </li>
        </ul>

        {/*Column2*/}
        <div className="column-3">
          <li>
            <Link
              to="/service-orchestration"
              className="alt-dropdown-heading"
              onClick={() => setClick(false)}
            >
              Service Orchestration
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/service-orchestration#workflow_automation"
              onClick={() => setClick(false)}
            >
              Workflow Automation
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/service-orchestration#network_automation"
              onClick={() => setClick(false)}
            >
              Network Automation
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/service-orchestration#cloud_transformation"
              onClick={() => setClick(false)}
            >
              Cloud Transformation
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/service-orchestration#xaas_"
              onClick={() => setClick(false)}
            >
              XaaS
            </Link>
          </li>
        </div>

        {/*Security*/}
        <ul className="column-3">
          <li>
            <Link
              to="/security-services"
              className="alt-dropdown-heading"
              onClick={() => setClick(false)}
            >
              Zero Trust Security
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/security-services#user"
              onClick={() => setClick(false)}
            >
              Identity Security
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/security-services#datacloud"
              onClick={() => setClick(false)}
            >
              Data & Cloud Security
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/security-services#cyber"
              onClick={() => setClick(false)}
            >
              Cyber Security
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/security-services#devsecops"
              onClick={() => setClick(false)}
            >
              DevSecOps
            </Link>
          </li>
        </ul>

        {/*Column3*/}
        <ul className="column-3">
          <li>
            <Link
              to="/full-stack-engineering"
              className="alt-dropdown-heading"
              onClick={() => setClick(false)}
            >
              Full Stack Engineering
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/full-stack-engineering#api-interoperability"
              onClick={() => setClick(false)}
            >
              API Interoperability
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/full-stack-engineering#cloud_native"
              onClick={() => setClick(false)}
            >
              Cloud Native
            </Link>
          </li>
        </ul>

        {/*Column4*/}
        <ul className="column-3">
          <li>
            <Link
              to="/infrastructure"
              className="alt-dropdown-heading"
              onClick={() => setClick(false)}
            >
              Infrastructure
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/infrastructure#hyper-converged"
              onClick={() => setClick(false)}
            >
              Hyper-Converged
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/infrastructure#hybrid-multi-cloud-platform"
              onClick={() => setClick(false)}
            >
              Hybrid Multi-Cloud Platform
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/infrastructure#unified-management-and-control"
              onClick={() => setClick(false)}
            >
              Unified Management and Control
            </Link>
          </li>
        </ul>

        {/* Column 5 */}

        <ul className="column-3">
          <li>
            <Link
              to="/services"
              className="alt-dropdown-heading"
              onClick={() => setClick(false)}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/finops"
              onClick={() => setClick(false)}
            >
              FinOps
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/services#strategic"
              onClick={() => setClick(false)}
            >
              Strategic
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/services#transformation"
              onClick={() => setClick(false)}
            >
              Transformation
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/services#engineering"
              onClick={() => setClick(false)}
            >
              Engineering
            </Link>
          </li>
          <li>
            <Link
              className="alt-dropdown-link"
              to="/services#support"
              onClick={() => setClick(false)}
            >
              Support
            </Link>
          </li>
        </ul>
      </ul>
    </>
  )
}

export default ServicesDropdown
