import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components/macro"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Bars from "../../images/bars.svg"
import LogoImg from "../../images/logo.png"
import { BiCaretDown } from "react-icons/bi"
import AboutDropdown from "./AboutDropdown"
import ServicesDropdown from "./ServicesDropdown"
import "../../styles/Navbar.scss"
import MoreDropdown from "./MoreDropdown"
import CountrySelector from "../CountrySelector"
import CountryDropdown from "./CountryDropdown"
import Globe from "../../images/globe.png"

const Nav = styled.nav`
  height: 80px;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 0rem;
  display: flex;
  z-index: 100;
  position: fixed;
  width: 100%;
  background: ${({ navbar }) =>
    navbar ? "rgba(16, 91, 133 ,0.60 )" : "rgb(16, 91, 133)"};
`
const NavItems = styled.div`
  height: 80px;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 6rem;
  display: flex;
  z-index: 100;
  position: fixed;
  width: 100%;
  max-width: 1400px;
`
const NavLink = css`
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
`
const Logo = styled(Link)`
  ${NavLink};
`
const MenuBars = styled.i`
  display: none;
  @media screen and (max-width: 960px) {
    display: block;
    background-image: url(${Bars});
    background-size: contain;
    height: 30px;
    width: 30px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-60%, 70%);
    filter: invert(100%);
  }
`
const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -48px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`
const NavMenuLinks = styled(Link)`
  ${NavLink}
  > * {
    margin-left: 5px;
  }
  &:hover {
    color: #0092a6;
  }
`
const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  @media screen and (max-width: 960px) {
    display: none;
  }
`

const Navbar = props => {
  const [navbar, setNavbar] = useState(false)
  const changeNavBG = () => {
    if (window.scrollY >= 80) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }
  //use window.addEventListener("scroll", changeNavBG) and cleanup
  useEffect(() => {
    window.addEventListener("scroll", changeNavBG)

    // cleanup this component
    return () => {
      window.addEventListener("scroll", changeNavBG)
    }
  }, [])
  const [click, setClick] = useState(false)
  const [aboutDropdown, setAboutDropdown] = useState(false) // need one per dropdown
  const [servicesDropdown, setServicesDropdown] = useState(false)
  const [moreDropdown, setMoreDropdown] = useState(false)
  const [countryDropdown, setCountryDropdown] = useState(false)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  const openAboutDropdown = () => {
    if (window.innerWidth < 960) {
      setAboutDropdown(false)
    } else {
      setAboutDropdown(true)
    }
  }
  const closeAboutDropdown = () => {
    if (window.innerWidth < 960) {
      setAboutDropdown(false)
    } else {
      setAboutDropdown(false)
    }
  }
  const openServicesDropdown = () => {
    if (window.innerWidth < 960) {
      setServicesDropdown(false)
    } else {
      setServicesDropdown(true)
    }
  }
  const closeServicesDropdown = () => {
    if (window.innerWidth < 960) {
      setServicesDropdown(false)
    } else {
      setServicesDropdown(false)
    }
  }
  const openMoreDropdown = () => {
    if (window.innerWidth < 960) {
      setMoreDropdown(false)
    } else {
      setMoreDropdown(true)
    }
  }
  const closeMoreDropdown = () => {
    if (window.innerWidth < 960) {
      setMoreDropdown(false)
    } else {
      setMoreDropdown(false)
    }
  }
  const openCountryDropdown = () => {
    if (window.innerWidth < 960) {
      setCountryDropdown(false)
    } else {
      setCountryDropdown(true)
    }
  }
  const closeCountryDropdown = () => {
    if (window.innerWidth < 960) {
      setCountryDropdown(false)
    } else {
      setCountryDropdown(false)
    }
  }

  return (
    <Nav navbar={navbar}>
      <NavItems>
        {/* <div className="mobile-country-selector" style={{ color: "#fff" }}>
          <CountrySelector /> UK
        </div> */}

        <Logo to="/">
          <StaticImage
            src="../../images/logo.png"
            alt="logo"
            placeholder="blurred"
            style={{
              width: "74px",
              objectFit: "contain",
            }}
          />
        </Logo>
        <MenuBars onClick={props.toggle} />
        {/*old*/}
        {/*<NavMenu>
      <NavMenuLinks
        to="/about"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        About
        <BiCaretDown />
      </NavMenuLinks>

      <NavMenuLinks to="/services">
        Services
        <BiCaretDown />
        {dropdown && <Dropdown />}
      </NavMenuLinks>

      <NavMenuLinks to="/about">Contact</NavMenuLinks>
      <NavMenuLinks to="/services">
        More
        <BiCaretDown />
      </NavMenuLinks>
      </NavMenu>*/}

        {/*new*/}

        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li
            className="nav-item"
            onMouseEnter={openAboutDropdown}
            onMouseLeave={closeAboutDropdown}
          >
            <div className="nav-dropdown" onClick={closeMobileMenu}>
              <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
                About <BiCaretDown />
              </Link>

              {aboutDropdown && <AboutDropdown />}
            </div>
          </li>

          <li className="nav-item">
            <Link to="/values" className="nav-links" onClick={closeMobileMenu}>
              Value
            </Link>
          </li>

          <li
            className="nav-item"
            onMouseEnter={openServicesDropdown}
            onMouseLeave={closeServicesDropdown}
          >
            <div className="nav-dropdown" onClick={closeMobileMenu}>
              Solutions & Services <BiCaretDown />
            </div>
            {servicesDropdown && <ServicesDropdown />}
          </li>

          <li
            className="nav-item"
            onMouseEnter={openMoreDropdown}
            onMouseLeave={closeMoreDropdown}
          >
            <div className="nav-dropdown" onClick={closeMobileMenu}>
              Insights and Resources <BiCaretDown />
            </div>
            {moreDropdown && <MoreDropdown />}
          </li>

          <li className="nav-item">
            <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
              Contact
            </Link>
          </li>

          {/*<li>
            <CountrySelector />
          </li>*/}
          {/* <li className="nav-item">
            <Link to="/privacy" className="nav-links" onClick={closeMobileMenu}>
              Privacy Policy
            </Link>
          </li> */}
          {/* <li
            className="nav-item"
            onMouseEnter={openCountryDropdown}
            onMouseLeave={closeCountryDropdown}
          >
            <div
              className="nav-dropdown"
              onClick={closeMobileMenu}
              style={{ lineHeight: "1", fontSize: "16px" }}
            >
              <img
                src={Globe}
                style={{
                  height: "30px",
                  filter: "invert(100%) brightness(2)",
                  padding: "0rem",
                }}
              />{" "}
              UK
            </div>
            {countryDropdown && <CountryDropdown />}
          </li> */}
        </ul>
      </NavItems>
    </Nav>
  )
}

export default Navbar
