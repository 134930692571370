import React, { useState } from "react"
import "../../styles/Submenu.scss"

const More = [
  {
    title: "News & Blogs",
    path: "/blogs/#news",
    target: "_self",
    id: "news-blogs",
    // submenu: [
    //   {
    //     title: "Press Releases ▼",
    //     path: "#",
    //     target: "_self",
    //     id: "press-releases",
    //     submenu: [
    //       {
    //         title: "Metsi 2.0",
    //         path: "/press-release",
    //         target: "_self",
    //       },
    //       {
    //         title: "Nubera Acquisition",
    //         path:
    //           "https://blog.metsi.com/metsi-group-ltd-acquires-technology-consulting-firm-nubera/",
    //         target: "_self",
    //       },
    //       {
    //         title: "Virtigon Acquisition",
    //         path: "https://blog.metsi.com/metsi-acquires-virtigon/",
    //         target: "_self",
    //       },
    //       {
    //         title: "Zero Trust Security",
    //         path:
    //           "https://blog.metsi.com/metsi-technologies-launches-new-zero-trust-security-services/",
    //         target: "_self",
    //       },
    //       {
    //         title: "Metsi-Snapt Partnership",
    //         path:
    //           "https://blog.metsi.com/metsi-technologies-joins-snapts-industry-leading-partner-program/",
    //         target: "_self",
    //       },
    //       {
    //         title: "Spectro Cloud Partnership",
    //         path: "https://blog.metsi.com/metsi-technologies-spectro-cloud/",
    //         target: "_self",
    //       },
    //       {
    //         title: "Bert Arnauts Joins Metsi",
    //         path:
    //           "https://blog.metsi.com/bert-arnauts-joins-metsi-technologies-as-sales-leader/",
    //         target: "_self",
    //       },
    //     ],
    //   },
    // ],
  },
  {
    title: "Resources",
    path: "/blogs/#resources",
    target: "_self",
    id: "resources",
    // submenu: [
    //   {
    //     title: "Videos",
    //     path: "https://www.youtube.com/channel/UC0VPmemAMa8voU6e2RDeGYQ/videos",
    //     target: "_blank",
    //     id: "videos",
    //   },
    // ],
  },
  {
    title: "Job Vacancies",
    path: "/job-application",
    target: "_self",
    id: "job-vacancies",
  },
]

const MoreDropdown = () => {
  const [click, setClick] = useState(false)
  const [submenu, setSubMenu] = useState(null)
  const [nestedSubmenu, setNestedSubmenu] = useState(null)

  const handleClick = () => setClick(!click)

  const handleMouseEnter = index => setSubMenu(index)
  const handleMouseLeave = () => setSubMenu(null)

  const handleNestedMouseEnter = index => setNestedSubmenu(index)
  const handleNestedMouseLeave = () => setNestedSubmenu(null)

  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? "dropdown-menu clicked" : "dropdown-menu"}
      >
        {More.map((item, index) => {
          return (
            <div key={index} onMouseLeave={handleMouseLeave}>
              <li>
                <a
                  className="dropdown-link"
                  href={item.path}
                  target={item.target}
                  onClick={() => setClick(false)}
                  id={item.id}
                  onMouseEnter={() => handleMouseEnter(index)}
                >
                  {item.title}
                </a>
              </li>
              {item.submenu && submenu === index && (
                <div className="pr-submenu">
                  {item.submenu.map((subitem, subindex) => (
                    <div
                      key={subindex}
                      onMouseEnter={() => handleNestedMouseEnter(subindex)}
                      onMouseLeave={handleNestedMouseLeave}
                    >
                      <a
                        className="dropdown-link2"
                        href={subitem.path}
                        target={subitem.target}
                        onClick={e => e.stopPropagation()}
                      >
                        {subitem.title}
                      </a>
                      {subitem.submenu && nestedSubmenu === subindex && (
                        <div className="pr-submenu">
                          {subitem.submenu.map((subsubmenu, subsubindex) => (
                            <a
                              key={subsubindex}
                              href={subsubmenu.path}
                              target={subsubmenu.target}
                              className="dropdown-link2"
                              onClick={e => e.stopPropagation()}
                            >
                              {subsubmenu.title}
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )
        })}
      </ul>
    </>
  )
}

export default MoreDropdown
