import React, { useState, useRef } from "react";
import Chevron from "./Chevron";
import {Link} from "gatsby"

import "../styles/Accordion.scss";

function Accordion(props) {
  const [setActive1, setActiveState1] = useState("");
  const [setHeight1, setHeightState1] = useState("0px");
  const [setRotate1, setRotateState1] = useState("accordion__icon");

  const [setActive2, setActiveState2] = useState("");
  const [setHeight2, setHeightState2] = useState("0px");
  const [setRotate2, setRotateState2] = useState("FooterAccordion__icon");

  const [setActive3, setActiveState3] = useState("");
  const [setHeight3, setHeightState3] = useState("0px");
  const [setRotate3, setRotateState3] = useState("FooterAccordion__icon");

  const content = useRef(null);

  function toggleAccordion1() {
    setActiveState1(setActive1 === "" ? "active" : "");
    setHeightState1(setActive1 === "active" ? "0px" : ``);
    setRotateState1(setActive1 === "active" ? "FooterAccordion__icon" : "FooterAccordion__icon rotate");

    setActiveState2(setActive2 === "active" ? "":"");
    setHeightState2(setHeight2 === "active" ? "0px":"0px");
    setRotateState2(setRotate2 === "FooterAccordion__icon");
        
    setActiveState3(setActive3 ===  "active" ? "":"");
    setHeightState3(setHeight3 === "active" ? "0px":"0px");
    setRotateState3(setRotate3 === "FooterAccordion__icon");

  }


  function toggleAccordion2() {
    setActiveState2(setActive2 === "" ? "active" : "");
    setHeightState2(setActive2 === "active" ? "0px" : ``);
    setRotateState2(setActive2 === "active" ? "FooterAccordion__icon" : "FooterAccordion__icon rotate");

    setActiveState1(setActive1 ===  "active" ? "":"");
    setHeightState1(setHeight1 === "active" ? "0px":"0px");
    setRotateState1(setRotate1 === "FooterAccordion__icon");

    setActiveState3(setActive3 ===  "active" ? "":"");
    setHeightState3(setHeight3 === "active" ? "0px":"0px");
    setRotateState3(setRotate3 === "FooterAccordion__icon");

  }

  function toggleAccordion3() {
    setActiveState3(setActive3 === "" ? "active" : "");
    setHeightState3(setActive3 === "active" ? "0px" : ``);
    setRotateState3(setActive3 === "active" ? "FooterAccordion__icon" : "FooterAccordion__icon rotate");

    setActiveState1(setActive1 ===  "active" ? "":"");
    setHeightState1(setHeight1 === "active" ? "0px":"0px");
    setRotateState1(setRotate1 === "FooterAccordion__icon");

    setActiveState2(setActive2 ===  "active" ? "":"");
    setHeightState2(setHeight2 === "active" ? "0px":"0px");
    setRotateState2(setRotate2 === "FooterAccordion__icon");

  }

  return (
    <div className="accordion__section">

         {/* HOME */}
         {/* <div style={{padding:"0rem 0rem 0.75rem 0rem", textAlign:"center" }}><Link to="/"  className="accordion__title2"  style={{ textDecoration:"none", fontWeight:"400", color:"#fff", padding:"1rem 0rem", textAlign:"left" }}> Home </Link>
        </div> */}


{/*1st Title and Chevron */}
      <button className={`accordion ${setActive1}`} style={{marginBottom:"-0.75rem"}} onClick={toggleAccordion1}>
        <p className="accordion__title" style={{fontSize:"22px"}}>{props.title1} &nbsp; <Chevron className={`${setRotate1}`} width={10} fill={"#fff"} /></p>
      </button>
      
      
      {/*1st Content */}
      <div
        ref={content}
        style={{ maxHeight: `${setHeight1}` }}
        className="accordion__content"
      >
        <div
          className="accordion__text"
          dangerouslySetInnerHTML={{ __html: props.content1 }}
        />
      </div>


           {/* VALUE */}
      <div  style={{padding:"0rem 0rem 0.75rem 0rem", textAlign:"center" }}><Link to="/values"  className="accordion__title2"  style={{ textDecoration:"none", fontWeight:"400", color:"#fff", padding:"1rem 0rem", textAlign:"left", fontSize:"22px" }}> Value </Link>
        </div>
 
 {/*2nd Title and Chevron */}
 <button className={`accordion ${setActive2}`} style={{marginBottom:"-0.75rem"}} onClick={toggleAccordion2}>
        <p className="accordion__title"  style={{fontSize:"22px"}}>{props.title2} &nbsp; <Chevron className={`${setRotate2}`} width={10} fill={"#fff"} /></p>
      </button>
      
      
      {/*2nd Content */}
      <div
        ref={content}
        style={{ maxHeight: `${setHeight2}` }}
        className="accordion__content"
      >
        <div
          className="accordion__text"
          dangerouslySetInnerHTML={{ __html: props.content2 }}
        />
      </div>
{/*3rd Title and Chevron */}
<button className={`accordion ${setActive3}`} style={{marginBottom:"-0.75rem"}} onClick={toggleAccordion3}>
        <p className="accordion__title" style={{fontSize:"22px"}}>{props.title3} &nbsp; <Chevron className={`${setRotate3}`} width={10} fill={"#fff"} /></p>
      </button>
      
      
      {/*3rd Content */}
      <div
        ref={content}
        style={{ maxHeight: `${setHeight3}` }}
        className="accordion__content"
      >
        <div
          className="accordion__text"
          dangerouslySetInnerHTML={{ __html: props.content3 }}
        />
      </div>

 
 
  

   {/* CONTACT US */}
      <div  style={{padding:"0rem 0rem 1rem 0rem", textAlign:"center"}}><Link to="/contact"  className="accordion__title2"  style={{ textDecoration:"none", fontWeight:"400", color:"#fff", padding:"0rem 0rem", textAlign:"left", fontSize:"22px"  }}> Contact Us </Link>
        </div>

  </div>
  );
}

export default Accordion;
