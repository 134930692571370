import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.scss"
import Navbar from "./Navbar/Navbar"
import MobileMenu from "./Navbar/MobileMenu"
import Footer from "./FooterNew"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"
import "../styles/consentbutton.scss"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
// import GeoLocator from "./Geolocator"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      {/* <GeoLocator /> */}
      <Navbar toggle={toggle} />
      <MobileMenu isOpen={isOpen} toggle={toggle} />
      <main>{children}</main>

      {/* <footer
        style={{
          marginTop: `2rem`,
          display: "flex",
        }}
      >
        © {new Date().getFullYear()}, Built by
        <a href="https://www.ozramedia.co.za"> Ozra media</a>
      </footer> */}

      <CookieConsent
        disableButtonStyles={true}
        // debug={true}
        location="bottom"
        style={{
          background: "#005b85",
          padding: "1.5rem",
          display: "flex",
          alignItems: "center",
        }}
        contentStyle={{
          display: "flex",
          margin: 0,
        }}
        contentClasses="concontent"
        buttonWrapperClasses="conbutwrap"
        buttonClasses="conbut"
        declineButtonClasses="conbut"
        buttonText="Accept"
        flipButtons
        enableDeclineButton
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        expires={1}
        onAccept={() => {}}
        onDecline={() => {
          alert(
            "We acknowledge that you have chosen to decline the sharing of your user data."
          )
        }}
      >
        <p>
          This website uses cookies in order to offer you the most relevant
          information. Please accept cookies for optimal performance. For full
          details, please view our &nbsp;
          <Link
            to="/privacy"
            style={{
              color: "#1ab4e9",
              textDecoration: "underline",
              mariginBottom: "-10px",
            }}
          >
            cookie policy.
          </Link>
        </p>
      </CookieConsent>

      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
