import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { About } from "../../data/SubMenuData"
import "../../styles/Submenu.scss"

const AboutDropdown = () => {
  const [click, setClick] = useState(false)
  const handleClick = () => setClick(!click)
  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? "dropdown-menu clicked" : "dropdown-menu"}
        style={{ marginLeft: "100px" }}
      >
        {About.map((item, index) => {
          return (
            <li key={index}>
              <Link
                className="dropdown-link"
                to={item.path}
                onClick={() => setClick(false)}
              >
                {item.title}
              </Link>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default AboutDropdown
